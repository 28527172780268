import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TermsPages from '../../partials/Terms/About'
import { dataTermsIndique60 } from '../../data/terms/terms-indique-60'

const TermsIndique60 = () => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages
      data={dataTermsIndique60}
      linkCanonical="termos-indique-e-ganhe/60"
    />
  </>
)

export default TermsIndique60
